<template>
	<div class="book-page">
		<!-- <main-banner></main-banner> -->
		<div class="return-btn">
			<md-button @click="backToSearch" class="goBack md-raised">
				&lt; Επιστροφή
			</md-button>
			<md-button md-button @click="clearSearch" class="goBack md-raised">
				<img
					width="15px"
					style="margin-left: 0px background-color: transparent"
					src="@/assets/img/clear.png"
					alt=""
				/>
				Νέα αναζήτηση
			</md-button>
		</div>
		<transition name="fadeIn" appear>
			<div class="single-book centering">
				<bib-loader v-if="isLoading" />
				<md-card v-if="!isLoading" class="card md-elevation-6">
					<span v-if="bookData.is_primary == 1" class="prm-book">Primary</span>
					<div class="book-container" :class="{ 'border-b': false }">
						<div v-if="hasPhotos" class="images-container">
							<div v-if="bookData.image_filename">
								<img
									@click="idx = 0"
									:src="bookCover"
									class="book-cover"
									alt="Image not found"
								/>
								<h3>Εξώφυλλο</h3>
							</div>
							<hr />
							<div class="gallery-container">
								<!-- <img
									class="image md-elevation-3"
									v-for="(image, i) in bookPhotos" :key="i"
									:src="image"
									@click="idx = i"
								> -->
								<carousel :per-page="4" :navigation-enabled="true">
									<slide v-for="(image, i) in bookPhotos" :key="i">
										<div style="margin: 3% 10%">
											<img
												:src="image"
												@click="idx = i"
												class="image md-elevation-3"
											/>
										</div>
									</slide>
									<!-- <slide></slide> -->
								</carousel>
							</div>
							<greca-gallery
								v-if="hasPhotos"
								@close="idx = null"
								:index="idx"
								:images="bookPhotos"
							/>
						</div>
						<!-- v-bind:class="{ active: isActive, 'text-danger': hasError }" -->
						<div
							:class="{ width65: hasPhotos, width100: !hasPhotos }"
							class="book-info"
						>
							<div class="book-prop">
								<h2>
									{{ bookData.ItemTitle }}
								</h2>
								<h3><span>Κωδικός Βιβλίου: </span> {{ bookData.ItemCode }}</h3>
							</div>
							<div class="line-1">
								<div class="gr">
									<div v-if="authorName">
										<span>Συγγραφέας: </span>
										<span
											:class="{ birthplace: bookData.author.thesId }"
											@click="goToThesaurus(bookData.author.thesId)"
										>
											{{ bookData.author.CreatorNameEL }}
										</span>
									</div>
									<div v-if="birthPlace">
										<span>Τόπος Γέννησης: </span>
										<span
											v-if="bookData.thesaurus"
											:class="{ birthplace: bookData.thesaurus.ThesaurusEntryID }"
											@click="goToThesaurus(bookData.thesaurus.ThesaurusEntryID)"
											>{{ bookData.author.CityNameEn }}</span
										>
										<span v-else>{{ bookData.author.CityNameEn }}</span>
										<img
											width="40px"
											:src="birthplaceMap"
											class="birth-map md-elevation-4"
											@click="showDialog = !showDialog"
											alt=""
										/>
										<map-modal
											:show-dialog="showDialog"
											:img-url="birthplaceMap"
											@close="showDialog = false"
										></map-modal>
									</div>
									<div v-if="bookData.PrintDate">
										<span>Χρονολογία: </span>
										{{ bookData.PrintDate }}
									</div>
								</div>
								<div class="en">
									<div v-if="authorName">
										<span>Author: </span>
										<span
											:class="{ birthplace: bookData.author.thesId }"
											@click="goToThesaurus(bookData.author.thesId)"
										>
											{{ bookData.author.CreatorNameEN }}
										</span>
									</div>
									<div v-if="birthPlace">
										<span>Birthplace: </span>
										<span
											v-if="bookData.thesaurus"
											:class="{ birthplace: bookData.thesaurus.ThesaurusEntryID }"
											@click="goToThesaurus(bookData.thesaurus.ThesaurusEntryID)"
											>{{ bookData.author.CityNameEl }}</span
										>
										<span v-else>{{ bookData.author.CityNameEl }}</span>
										<img
											width="40px"
											:src="birthplaceMap"
											class="birth-map md-elevation-4"
											@click="showDialog = !showDialog"
											alt=""
										/>
										<map-modal
											:show-dialog="showDialog"
											:img-url="birthplaceMap"
											@close="showDialog = false"
										></map-modal>
									</div>
									<div v-if="bookData.PrintDate">
										<span>Year: </span>
										{{ bookData.PrintDate }}
									</div>
								</div>
							</div>

							<div class="line-1">
								<div class="gr">
									<div v-if="publisherName">
										<span>Εκδότης: </span>
										<span
											:class="{ birthplace: bookData.publisher.thesId }"
											@click="goToThesaurus(bookData.publisher.thesId)"
										>
											{{ bookData.publisher.OwnerNameEL }}
										</span>
									</div>
									<div v-if="pPlaceName" :class="{ 'margin-top-2': birthPlace }">
										<span>Τόπος 'Εκδοσης: </span>
										<span
											:class="{ birthplace: bookData.p_city.thesId }"
											@click="goToThesaurus(bookData.p_city.thesId)"
										></span>
										{{ bookData.p_city.CityNameEL }}
									</div>

									<div v-if="publisherName">
										<span>Χρονολογια: </span>
										{{ publishedDate }}
									</div>
									<div
										v-if="categoryName"
										class="book-prop"
										:class="{ 'margin-top-2': birthPlace }"
									>
									<span>Κατηγορία Έργου: </span>
										{{ categoryName }}
									</div>
									<div v-if="wPlaceName" class="book-prop">
										<span>Τόπος Συγγραφής: </span>
										{{ bookData.w_city.CityNameEL }}
									</div>
									<div v-if="bookData.origin" class="book-prop">
										<span>Προέλευση: </span>
										{{ bookData.origin }}
									</div>
								</div>
								<div class="en">
									<div v-if="publisherName">
										<span>Publisher: </span>
										<span
											:class="{ birthplace: bookData.publisher.thesId }"
											@click="goToThesaurus(bookData.publisher.thesId)"
										>
											{{ bookData.publisher.OwnerNameEN }}
										</span>
									</div>
									<div v-if="pPlaceName" :class="{ 'margin-top-2': birthPlace }">
										<span> Publication Place: </span>
										<span
											:class="{ birthplace: bookData.p_city.thesId }"
											@click="goToThesaurus(bookData.p_city.thesId)"
										></span>
										{{ bookData.p_city.CityNameEN }}
									</div>

									<div v-if="publisherName">
										<span>Year: </span>
										{{ publishedDate }}
									</div>
									<div
										v-if="categoryName"
										class="book-prop"
										:class="{ 'margin-top-2': birthPlace }"
									>
									<span>Category: </span>
										{{ categoryName }}
									</div>
									<div v-if="wPlaceName" class="book-prop">
										<span>Authoring Place: </span>
										{{ bookData.w_city.CityNameEN }}
									</div>
									<div v-if="bookData.origin" class="book-prop">
										<span>Origin: </span>
										{{ bookData.origin }}
									</div>
								</div>
							</div>

						</div>
					</div>
					<div v-if="bookData.CommentsM" class="book-description">
						<div style="width: 100% margin-bottom: 2%">
							<hr />
						</div>
						<span style="font-weight: bold">Περιγραφή: </span>
						<div>
							<span
								v-for="string in bookData.CommentsM"
								:key="string.id"
							>
								<span
									class="description-content"
									v-if="!isLink(string)"
									v-html="string"
								></span>
								<v-popover
									v-if="isLink(string)"
									trigger="hover"
									:placement="'top-center'"
									:loadingContent="'Loading ...'"
									@show="showInfo(string)"
									@hide="clearTooltip()"
									:handleResize="true"
								>
									<!-- This will be the popover target (for the events and position) -->
									<span
										class="description-content tooltip-target b1"
										v-html="string"
										@click.prevent="goToThesaurus(linksID)"
									></span>

									<!-- This will be the content of the popover -->
									<template slot="popover">
										<p v-if="!thesDescr">
											Loading ...
										</p>
										<p v-if="thesDescr">{{ thesDescr }} . . .</p>
										<!-- You can put other components too -->
									</template>
								</v-popover>
							</span>
						</div>
					</div>
					<div v-if="bookData.bibliography" class="book-description">
						<span style="font-weight: bold">Βιβλιογραφία: </span>
						<span
							class="description-content"
							v-html="bookData.bibliography"
						></span>
					</div>
				</md-card>
			</div>
		</transition>

		<div class="books-deco">
			<div class="inner"></div>
		</div>
	</div>
</template>

<script>
import { searchMixin } from "@/mixins/searchMixin"
import { mapActions } from "vuex"
import GrecaGallery from "@/components/ui/GrecaGallery"
import MapModal from "@/components/ui/MapModal"
import { Carousel, Slide } from "vue-carousel"

export default {
	name: "SingleBook",
	mixins: [searchMixin],
	components: {
		GrecaGallery,
		MapModal,
		Carousel,
		Slide
	},
	data() {
		return {
			bookData: {},
			showDialog: false,
			bookPhotos: [],
			idx: null,
			hoveredItem: null,
			isLoading: true,
			isEnabled: false,
			linksID: null,
			thesDescr: ""
		}
	},
	computed: {
		hasPhotos() {
			return (
				this.bookPhotos.length >= 1 || this.bookData.image_filename !== null
			)
		},
		authorName() {
			if (this.bookData.author) {
				return this.clearData(
					`${this.bookData.author.CreatorNameEL} - ${this.bookData.author.CreatorNameEN}`
				)
			}
			return ""
		},
		birthPlace() {
			if (this.bookData.author) {
				return this.clearData(
					`${this.bookData.author.CityNameEl} - ${this.bookData.author.CityNameEn}`
				)
			}
			return ""
		},
		publisherName() {
			if (this.bookData.author) {
				return this.clearData(
					`${this.bookData.publisher.OwnerNameEL} - ${this.bookData.publisher.OwnerNameEN}`
				)
				// return `${this.bookData.publisher.OwnerNameEL} - ${this.bookData.publisher.OwnerNameEN}`
			}
			return ""
		},
		categoryName() {
			if (this.bookData.category) {
				return this.clearData(
					`${this.bookData.category.DescriptionEL} - ${this.bookData.category.DescriptionEN}`
				)
			}
			return ""
		},
		wPlaceName() {
			if (this.bookData.w_city) {
				return this.clearData(
					`${this.bookData.w_city.CityNameEL} - ${this.bookData.w_city.CityNameEN}`
				)
			}
			return ""
		},
		pPlaceName() {
			if (this.bookData.p_city) {
				return this.clearData(
					`${this.bookData.p_city.CityNameEL} - ${this.bookData.p_city.CityNameEN}`
				)
			}
			return ""
		},
		writtenDate() {
			if (this.bookData.PrintDate) {
				if (this.bookData.PrintDate.includes("-")) {
					return this.bookData.PrintDate.replace("-", "") + "π.Χ."
				}
				return this.bookData.PrintDate + "μ.Χ."
			}
			return ""
		},
		publishedDate() {
			if (this.bookData.CreationDate) {
				if (this.bookData.CreationDate.includes("-")) {
					return this.bookData.CreationDate.replace("-", "") + "π.Χ."
				}
				return this.bookData.CreationDate
			}
			return ""
		},

		bookCover() {
			return `${this.$store.state.imgUrl}${this.bookData.image_filename}`
		},
		birthplaceMap() {
			if (this.bookData.thesaurus) {
				return `${this.$store.state.imgUrl}${this.bookData.thesaurus.ThesaurusImage}`
			}
			return ""
		}
	},
	created() {
		this.getBook()
	},
	methods: {
		...mapActions(["searchData"]),
		getBook() {
			let url = `${this.$store.state.rootUrl}book/${this.$route.params.bookId}`
			this.$http
				.get(url)
				.then(response => {
					this.bookData = { ...response.data.data }
					// console.log(this.bookData)
					this.isLoading = false
					let album = []
					if (this.bookData.photos) {
						album = this.getPhotos(this.bookData.photos)
					}
					this.bookPhotos = [this.bookCover, ...album]
				})
				.catch(error => {
					if (error.response.status === 404) {
						this.$router.push("/search")
					} else {
						this.$router.push("/")
					}
				})
		},
		getPhotos(photos) {
			this.bookPhotos[0] = this.bookCover
			let album = photos.map(i => {
				let photoUrl = i.replace("/thumbs", "")
				photoUrl = photoUrl.split("JPG").join("jpg")
				return `${this.$store.state.imgUrl}${photoUrl}`
			})
			return album
		},
		clearData(info) {
			if (info.startsWith(" - ") || info.endsWith(" - ")) {
				info = info.replace(" - ", "")
			}
			return info
		},
		backToSearch() {
			this.$router.back()
		},
		goToThesaurus(e) {
			if (e) {
				this.$router.push({ path: `/thesaurus/${e}` })
			}
		},
		isLink(str) {
			return str.startsWith("<")
		},
		showInfo(e) {
			this.clearTooltip()
			const regex = /data-id=['|"](\d+)['|"]/gm
			this.linksID = regex.exec(e)[1]
			let url = `${this.$store.state.rootUrl}thesaurus/${this.linksID}`
			this.$http.get(url).then(res => {
				// console.log(res.data.data.EntryDescription)
				this.thesDescr = res.data.data.EntryDescription.substring(0, 100)
			})
		},
		clearTooltip() {
			this.thesDescr = null
		},
		getTooltipResponse() {
			return `hoveredItem is ${this.hoveredItem}`
		},
		clearSearch() {
			this.returnFromBook()
			this.$router.push({ name: "search" })
		}
	},
	watch: {
		thesDescr(x, y) {
			return this.thesDescr
		}
	},
	mounted() {
		// this.$nextTick(() => {
		// })
	}
}
</script>

<style lang="scss" scoped>
.fadeIn-enter-active {
	opacity: 1;
	transition: all 3s ease;
}

.fadeIn-enter {
	opacity: 0;
}

.book-page {
	background-image: url("../assets/img/2.jpg");
	background-size: contain;
	background-repeat: repeat;
	padding-top: 2%;
}

.return-btn {
	height: 2vw;
	display: flex;
	padding: 1% 15%;
}

.goBack {
	color: #000;
	text-transform: capitalize;
	border-radius: 7px;
	width: 170px;
	font-size: calc(14px + (17 - 14) * ((100vw - 300px) / (1600 - 300)));
	cursor: pointer;
	height: 40px;

	span {
		font-size: calc(17px + (20 - 17) * ((100vw - 300px) / (1600 - 300)));
	}
}

.single-book {
	padding: 5% 15%;
}

.card {
	background-color: #fff;
	border-radius: 24px;
	width: 100%;
	padding: 10px;
}

	.prm-book {
    color: #fff;
    position: absolute;
    float: right;
    margin-left: auto;
    font-weight: bold;
    right: 12px;
    top: 9px;
    background-color: #b15e27;
    border-radius: 13px 2px 13px;
    padding: 6px 8px;
	}

.border-b {
	border-bottom: 1px solid $grey;
}

.book-container {
	display: flex;
	padding: 3% 0 1% 0;
	margin: 0 3%;
	flex-wrap: nowrap;

	.images-container {
		width: 35%;

		.book-cover {
			max-height: 450px;
			cursor: pointer;
		}

		h3 {
			font-weight: bold;
			margin: 3% 0 5% 0;
		}

		.image {
			margin: 2% 3%;
		}
	}

	.gallery-container {
		width: 100%;
		margin-top: 3%;
		padding: 0 6%;

		img {
			max-height: 130px;
			margin: 2% 0;
			cursor: pointer;
		}
	}

	.book-info {
		padding-left: 4%;
		padding-right: 1%;
		// width: 65%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.width100 {
		width: 100% !important;
		padding-left: 1%;

		.book-prop:not(:first-child) {
			margin: 2% 0;
		}
	}

	.book-prop {
		width: 100%;
		text-align: left;

		span:not(.birthplace) {
			font-weight: bold;
			font-size: 0.9rem;
		}

		h3 {
			font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
			font-style: italic;
			padding-bottom: 5%;
		}
	}

	.book-info .book-prop:first-child {
		border-bottom: 1px solid $grey;
		margin-bottom: 2%;

		h2 {
			font-size: calc(16px + (35 - 16) * ((100vw - 300px) / (1600 - 300)));
			padding: 2px 0 5% 0;
			font-weight: bold;
			line-height: 1.3;
		}
	}

	.book-prop:not(:first-child) {
		margin: 3% 0;
		font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
	}

	.line-1 {
		display: flex;
		justify-content: space-between;
		width: 100%;

		.gr, .en {
			text-align: left;
			margin: 8px 0;
			width: 50%;

			> div {
				margin: 6px 0;
				font-size: 0.9rem;

				span:first-child {
					font-weight: bold;
				}
			}
		}

		.en { padding-left: 1rem }
	}
}

.book-description {
	text-align: left;
	padding: 0% 3% 2% 3%;

	span:first-child {
		// font-weight: bold;
	}

	span {
		// font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
		font-size: 1.1rem;
	}
}

.description-content {
	line-height: 1.7;
}

.books-deco {
	width: 100%;
	height: 20vw;

	.inner {
		background-image: url("../assets/img/photo_books_1.png");
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-color: transparent;
		width: 100%;
		padding: 10% 0;
	}
}

.birthplace {
	cursor: pointer;
	color: #448aff;
}

.birthplace:hover {
	// text-shadow: 0 1px #000;
	// font-weight: bold;
	text-decoration: underline;
}

.birth-map {
	margin-left: 15px;
	cursor: pointer;
}

.margin-top-2 {
	margin-top: 2% !important;
}
@media (max-width: 870px) {

	.book-container .book-info {
		padding-left: 0;
	}

	.return-btn {
		height: 4vw;
	}
}

@media (min-width: 1025px) {
	.prm-book  {
    font-size: 1.2rem;
	}
}

@media (min-width: 850px) {
	.width65 {
		width: 65% !important;
	}
}

@media (max-width: 770px) {
	.single-book {
		padding: 7% 5%;
	}

	.return-btn {
		padding: 3% 5%;
		height: 8vw;
	}

	.book-container {
		flex-direction: column;
		align-items: flex-start;

		.images-container {
			width: 100%;
			margin-bottom: 15%;

			img {
				max-height: 250px;
			}
			.image {
				max-height: 125px;
			}
		}

		.book-info {
			width: 100%;

			h2 {
				text-align: center;
			}
		}

		.gallery-container {
			justify-content: center;
		}
	}
}

@media (max-width: 470px) {
	.return-btn {
		height: 11vw;
	}
}

@media(max-width: 720px) {

.single-book {
	padding: 5% 3%;
}
}
</style>
