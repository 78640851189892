<template>
	<div class="book-page">
		<!-- <main-banner></main-banner> -->
			<md-button @click="backToSearch" class="goBack md-raised">
				&lt; Επιστροφή
			</md-button>
		<transition name="fadeIn" appear>
			<div class="single-book centering">
				<bib-loader v-if="isLoading" />
				<md-card v-if="!isLoading" class="card md-elevation-6">
					<div class="book-container" :class="{'border-b': thesData.CommentsM}">
						<div v-if="hasPhotos" class="images-container">
							<div v-if="thesData.image_filename">
								<img :src="bookCover" class="book-cover" alt="Image not found">
								<h3>Εξώφυλλο</h3>
							</div>
							<hr v-if="thesData.image_filename">
							<div class="gallery-container">
								<img
									class="image md-elevation-3"
									v-for="(image, i) in thesPhotos" :key="i"
									:src="image"
									@click="idx = i"
								>
							</div>
							<greca-gallery @close="idx=null" v-if="hasPhotos" :index="idx" :images="thesPhotos"/>
						</div>
						<!-- v-bind:class="{ active: isActive, 'text-danger': hasError }" -->
						<div :class="{ 'width65': hasPhotos, 'width100': !hasPhotos }" class="book-info">
							<div class="book-prop">
								<h2>
									{{ thesData.EntryName }}
								</h2>
							</div>
							<div v-if="thesData.EntryDescription" class="book-description">
								<!-- <span>Περιγραφή: </span>
								<span class="description-content" @click.prevent="goToBook($event.target.dataset.bookid)" v-html="thesData.EntryDescription"></span> -->
								<bib-tabs
									:description="thesData.EntryDescription"
									:wiki= "thesData.W"
									:sou = "thesData.SOY"
									:dl = "thesData.DL"
								></bib-tabs>
							</div>
						</div>
					</div>
				</md-card>
			</div>
		</transition>

		<div class="books-deco">
			<div class="inner"></div>
		</div>
	</div>
</template>

<script>
import GrecaGallery from "@/components/ui/GrecaGallery"
import BibTabs from "@/components/ui/BibTabs"
export default {
	name: "Thesaurus",
	components: {
		GrecaGallery,
		BibTabs
	},
	data() {
		return {
			thesData: {
				// ThesaurusEntryID: '',
				// EntryName: '',
				// EntryDescription: '',
				// ThesaurusImage: '',
				// ThesaurusImage2: '',
				// ThesaurusImage3: '',
				// ThesaurusImage4: '',
				// ThesaurusImage5: '',
				// ThesaurusImage6: ''
			},
			isLoading: true,
			thesPhotos: [],
			idx: null
		}
	},
	computed: {
		hasPhotos() {
			return this.thesPhotos.length > 0
		}
	},
	created() {
		this.getThes()
	},
	methods: {
		getThes() {
			let url = `${this.$store.state.rootUrl}thesaurus/${this.$route.params.thesId}`
			this.$http.get(url)
				.then((response) => {
					this.thesData = { ...response.data.data }
					this.isLoading = false
					// console.log(this.thesData)
					this.getPhotos(this.thesData)
				}).catch(error => {
					if (error.response.status === 404) {
						this.$router.push('/search')
					} else {
						this.$router.push('/')
					}
				})
		},
		getPhotos(thesObj) {
			const entries = Object.entries(thesObj)
			for (const [imgSlot, path] of entries) {
				if (path && imgSlot.startsWith("ThesaurusImage")) {
					this.thesPhotos.push(`${this.$store.state.imgUrl}${path}`)
				}
			}
		},
		// goToBook(e) {
		// 	console.log(e)
		// 	let url = `${this.$store.state.rootUrl}mainbook/${e}`
		// 	// console.log(url)
		// 	this.$http.get(url)
		// 		.then((res) => {
		// 			const bookId = res.data.data
		// 			// console.log(bookId)

		// 			this.$router.push({ path: `/book-profile/${bookId}` })
		// 		})
		// },

		backToSearch() {
			this.$router.back()
		}
	},
	mounted() {
	}
}
</script>

<style lang="scss" scoped>
	.fadeIn-enter-active {
		opacity: 1;
		transition: all 3s ease;
	}

	.fadeIn-enter {
		opacity: 0;
	}

	.book-page {
		background-image: url('../assets/img/2.jpg');
		background-size: contain;
		background-repeat: repeat;
		padding-top: 2%;
	}

	.return-btn {
		// height: 2vw;
		display: flex;
		padding: 1% 15%;
	}

	.goBack {
		margin-left: -61%;
		color: #000;
		text-transform: capitalize;
		border-radius: 7px;
		width: 130px;
		font-size: calc(14px + (17 - 14) * ((100vw - 300px) / (1600 - 300)));
		cursor: pointer;
		height: 40px;

		span {
			font-size: calc(17px + (20 - 17) * ((100vw - 300px) / (1600 - 300)));
		}
	}
	.single-book {
		padding: 5% 15%;
	}

	.card {
		background-color: #fff;
		border-radius: 24px;
		width: 100%;
	}

	.border-b {
		border-bottom: 1px solid $grey;
	}

	.book-container {
		display: flex;
		padding: 3% 0 1% 0;
		margin: 0 3%;
		flex-wrap: nowrap;

		.images-container {
			width: 35%;

			.book-cover {
				max-height: 450px;
			}

			h3 {
				font-weight: bold;
				margin: 3% 0 5% 0;
			}

			.image {
				margin: 2% 3%;
			}
		}

	.gallery-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		justify-content: flex-start;
		margin-top: 3%;

		img {
			max-height: 130px;
			margin: 2% 0;
			cursor: pointer;
		}
	}

		.book-info {
			padding-left: 4%;
			padding-right: 4%;
			// width: 65%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		.width100 {
			width: 100%!important
		}

		.book-prop {
			width: 100%;
			text-align: left;

			span {
				font-weight: bold;
			}
		}

		.book-info .book-prop:first-child {
			border-bottom: 1px solid $grey;

			h2 {
				font-size: calc(16px + (35 - 16) * ((100vw - 300px) / (1600 - 300)));
				padding: 2px 0 5% 0;
				font-weight: bold;
				line-height: 1.3;
			}
		}

		.book-prop:not(:first-child) {
			margin: 3% 0;
			font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));

		}
	}

	.book-description {
		text-align: left;
		padding: 3% 0;

		span:first-child {
			font-weight: bold;
		}

		span {
			font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
		}

	}

	.description-content {
		line-height: 1.7;
	}

	.books-deco {
	width: 100%;
	height: 20vw;

	.inner {
		background-image: url('../assets/img/photo_books_1.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-color: transparent;
		width: 100%;
		padding: 10% 0;
	}
}

@media (min-width: 960px) {
		.width65 {
		width: 65%!important
	}

}

@media (max-width: 960px) {
	.book-container {
		flex-direction: column;
		align-items: flex-start;

		.images-container {
			width: 100%;
			margin-bottom: 15%;

			img {
				max-height: 250px;
			}
			.image {
				max-height: 125px;
			}
		}

		.book-info {
			width: 100%;

			h2 {
				text-align: center;
			}
		}

		.gallery-container {
			justify-content: center;
		}

	}
}

@media(max-width: 720px) {

	.single-book {
		padding: 5% 3%;
	}
	}
</style>
